<script type="text/javascript" context="module">
  export const api = {
    authToken: "",

    get: function(url) {
      return fetch(url, {
        mode: "cors",
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json"
          // Authorization: "Basic " + api.authToken
        })
      })
        .then(function(res) {
          return res.json();
        })
        .then(function(json) {
          return json;
        });
    },

    post: function(url, data) {
      return fetch(url, {
        mode: "cors",
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          "Content-Type": "application/json"
          //Authorization: "Basic " + api.authToken
        }),
        body: JSON.stringify(data)
      })
        .then(function(res) {
          return res.json();
        })
        .then(function(json) {
          return json;
        });
    }
  };
</script>
