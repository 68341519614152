<script>
  import { api } from "../utils/api.svelte";
  import { createEventDispatcher } from "svelte";
  import { beforeUpdate, afterUpdate, onMount } from "svelte";

  export let data;
  export let selected;
  export let live;
  export let isScrolling;
  // console.log(data);
  var slide = {};
  export const getSlide = () => {
    return slide;
  };
  const dispatch = createEventDispatcher();

  beforeUpdate(() => {
    //as-per docs at https://mpcas.10net.net/api/v1/connections/upcoming-playouts
    slide.id = data.id;
    if ("artwork" in data) {
      slide.type = "artwork";
      slide.data_id = data.artwork.artwork_id;
      slide.photo = data.artwork.photo_main;
      slide.artists = data.artwork.artists.map(function(artist, index) {
        if (index == 0) {
          return artist.name;
        } else {
          return ", " + artist.name;
        }
      });
    } else if ("program" in data) {
      slide.type = "program";
      slide.data_id = data.program.program_id;
      slide.photo = data.program.photo_main;
      slide.artist_id = data.program.curator; //singular
    } else {
      slide.type = "none";
      slide.artist_id = "";
      slide.photo = "./img/no-artwork.jpg";
    }

    const promises = [];
    /*
  if (slide.artist_id) {
    promises.push(
      api
        .get(
          "https://mpcas.10net.net/api/v1/artists/" +
            slide.artist_id +
            "/?format=json"
        )
        .then(res => {
          slide.artist = res;
          return Promise.resolve();
        })
    );
  }
  if (slide.type == "artwork" && slide.data_id) {
    promises.push(
      api
        .get(
          "https://mpcas.10net.net/api/v1/artworks/" +
            slide.data_id +
            "/?format=json"
        )
        .then(res => {
          slide.artwork = res;
          return Promise.resolve();
        })
    );
  }
  if (slide.type == "program" && slide.data_id) {
    promises.push(
      api
        .get(
          "https://mpcas.10net.net/api/v1/programs/" +
            slide.data_id +
            "/?format=json"
        )
        .then(res => {
          slide.program = res;
          return Promise.resolve();
        })
    );
  }
  */

    $: checkSelected(data);
    function checkSelected() {
      if (data.id == 0) {
        window.selectFirstArt = function() {
          //dispatch("selectSlide", data);
        };
        setTimeout(function() {
          // dispatch("selectSlide", slide);
        }, 500);
      }
    }

    // TODO - handle erroring of all
    /*Promise.all(promises).then(res => {
    console.log("all data");
    if (selected) {
      handleClick();
    }
  });*/

    const date = new Date(data.start_time.replace(/ /g,"T"));
    const localTime = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
    slide.start_time = localTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true
    });

     var slideTitle = slide.start_time;

    if(live){
      slideTitle = "Now Playing";
    }
    if(date > Date.now()){
      slideTitle = "Up Next";
    }
  });


  var handleClick = function(event) {
    //show title, description, artist & photo in top pane

    if (!isScrolling) {
      dispatch("selectSlide", data);
    }
    // could consider flashing red rectangle or 'no info' on the slide to make it clear.
  };

  
</script>

<style>
  .slide {
    height: 85%;
    width: 33vh; /* TODO - (height var)/9 * 16 - padding  [so its always 16:9] */
    position: relative;
    display: grid;

    margin-left: 10px;
    margin-right: 10px;

    margin-bottom: -24px;
    grid-template-rows: 1fr 24px;
    background-color: black;
    .preview {
      outline-offset: -2px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      .status {
        background: red;
        color: white;
        text-transform: uppercase;
        border: 1px solid white;
        opacity: 70%;
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 3px 8px;
        border-radius: 4px;
        font-size: 14px;
        display: none;
      }
    }

    .timestamp {
      display: flex;
      align-items: center;
      color: white;
      font-size: 12px;
      opacity: 0.5;
    }

    &:hover {
      .preview {
        outline: 2px white solid;
      }
    }

    &.selected {
      color: black;
      .preview {
        outline: 2px red solid;
      }
    }

    &.live {
      .status {
        display: block;
      }
    }
  }
</style>

{#if slide.type != 'none'}
  <div
    class="slide {selected ? 'selected' : ''}
    {live ? 'live' : ''}"
    on:click={handleClick}>
    <div class="preview" style="background-image: url('{slide.photo}')">
      <div class="status">LIVE</div>
    </div>
    <div class="timestamp">{live ? 'Now Playing' : slide.start_time}</div> 
  </div>
{/if}
