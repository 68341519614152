<script type="text/javascript">
  export let visible;
</script>

<style type="text/css">
	::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  // Track
  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(240, 240, 240, 0.1);
  }
  // Handle
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(150, 150, 150, 0.8);
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(200, 200, 200, 0.4);
  }

  .background {
    z-index: 3;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    min-height: 100vh;
    opacity: 0;
    transition: opacity 0.5s;
    overflow-x: hidden;
    overflow-y: auto;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: multiply;

    &.visible {
      opacity: 1;
      z-index: 10;
    }
  }

  .text {
    padding: 4vh 4vw 0 4vw;
    opacity: 0;
    transition: opacity 0.5s;
    z-index: 3;
    &.visible {
      opacity: 1;
    }

            a {
      color:white;
      text-decoration: underline;

    }

    h1 {
      font-size: 24px;
      margin: 10px 0;
      font-weight: 600;
      text-transform: capitalize;
    }
    h2 {
      font-size: 18px;
      margin: 15px 0;
      font-weight: 200;
      font-weight: 400;
    }
    p {
      font-size: 14px;
      margin: 10px 0;
      font-weight: 300;
      line-height: 1.55em;
    }

    @media (min-width: 768px) {
      h1 {
        font-size: 32px;
        margin: 20px 0;
      }
      h2 {
        font-size: 20px;
        margin: 30px 0;
      }
      p{
        font-size: 16px;
        width: 66vw;
        margin: 20px 0;
      }
    }

    @media (min-width: 992px) {
      h1 {
        font-size: 48px;
        margin: 20px 0;
      }
      h2 {
        font-size: 20px;
        margin: 30px 0;
      }
      p{
        font-size: 16px;
        width: 66vw;
        margin: 20px 0;
      }
    }
  }
</style>

<div
  class="background {visible ? 'visible' : ''}"
  style="background-color: black">
  <div class="text {visible ? 'visible' : ''}">

  <h1>ABOUT</h1>
<h2>WELCOME TO THE MOUNT PLEASANT COMMUNITY ART SCREEN (MPCAS)</h2>

<p>The MPCAS is an outdoor urban screen located on unceded Coast Salish territories, in the Mount Pleasant neighbourhood of Vancouver, BC, Canada. Launched in December 2019, the MPCAS reflects its neighbourhood through artwork by local and commissioned artists, with a special focus on works exploring the area’s history, its current vitality and its future. This art-specific urban screen brings new digital technology to Mount Pleasant and the City of Vancouver with non-commercial programming around the theme of PLACE, presenting a diverse range of visual and media art by dozens of artists, community members, and community festivals reflecting on what it is to live in a changing Mount Pleasant neighbourhood.</p>
<p>The screen is programmed by <a href="https://grunt.ca/">grunt gallery</a> on an ongoing basis, via open calls for submissions, community-based outreach, collaborations and curated programs.</p>
<p>Location: Intersection of Broadway & Kingsway, Vancouver, on the side of the Independent Building.</p>
<p><b>Autumn / Winter Hours (01 October to 31 March)</b><br> 
<b>Sunday to Thursday:</b> 9:00 AM to 9:30 PM <br>
<b>Friday & Saturday:</b>  9:00 AM to 10:30 PM</p>
<p><b>Spring / Summer Hours (01 April to 30 September)</b><br>
<b>Sunday to Thursday:</b> 9:00 AM to 10:00 PM <br>
<b>Friday & Saturday:</b>  9:00 AM to 11:00 PM</p>
<p><a href="https://grunt.ca/">grunt gallery</a> and the Mount Pleasant Community Art Screen are located on the unceded and ancestral territories of the xwməθkwəy̓əm (Musqueam), Skwxwú7mesh (Squamish) and Səl̓ílwətaʔ/Selilwitulh (Tsleil- Waututh) Nations, who have lived in kinship with this land, water and air for thousands of years. We recognize and acknowledge their leadership and our own complicity in settler colonialism, its present occupation and its violent legacy. We acknowledge our responsibility to work actively in support of Indigenous sovereignty, and towards a respectful relationship with this place.</p>
    
  </div>
</div>
